<template>
    <div class="userOnline">
        <div class="userOnlineSub">
            <div class="userOnlineTitle">
                <div class="name">{{ $route.meta.name }}</div>
                <div>
                    <el-date-picker size="mini" :clearable="false" v-model="chartDaterange" type="daterange" range-separator="～" start-placeholder="开始日期" format="yyyy-MM-dd" end-placeholder="结束日期"></el-date-picker>
                </div>
            </div>
            <div class="userOnlineCon" ref="echarts" v-loading="echartsLoading">
            </div>
        </div>
        <div class="userOnlineSub">
            <div class="userOnlineTitle">
                <div class="name">{{ $route.meta.name }}</div>
                <div>
                    <el-date-picker size="mini" :clearable="false" v-model="listDaterange" type="daterange" range-separator="～" start-placeholder="开始日期" format="yyyy-MM-dd" end-placeholder="结束日期"></el-date-picker>
                </div>
            </div>
            <div class="userOnlineCon">
                <el-table ref="multipleTable" :data="tableData" :default-sort ="{prop:'createDate',order:'descending'}" border tooltip-effect="dark" height="string"  v-loading="loading" header-cell-class-name="meterHeader">

                    <el-table-column align="center" prop="townId" label="镇/街"></el-table-column>
                    <el-table-column align="center" prop="landUseName" label="宗地"></el-table-column>
                    <el-table-column align="center" prop="createDate" label="点击时间"></el-table-column>
                    <el-table-column align="center" prop="type" label="点击路径"></el-table-column>
                    <el-table-column align="center" prop="ip" label="点击ip地址"></el-table-column>

                </el-table>
                <el-pagination
                    @current-change="pageChangeHandler"
                    @size-change="handleSizeChange"
                    :current-page="parameter.pageNumber"
                    :page-size="parameter.pageSize"
                    prev-text="上一页"
                    next-text="下一页"
                    :total="parameter.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import mixinsList from '@/mixins/list'
import * as echarts from "echarts";
export default {
    components: {

    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                findPage:this.$api['admin/statistics'].findByPage
            },
            chartDaterange:[new Date().getMonthStar(),new Date().getMonthEnd()],
            listDaterange:[new Date().getMonthStar(),new Date().getMonthEnd()],
            echartsLoading:true,
        };
    },
    inject:['adaptation'],
    watch:{
        listDaterange: {
            deep: true,
            immediate: true,
            handler: function(val, oldVal) {
                this.parameter.condition.startDate = val[0].format('yyyy-MM-dd');
                this.parameter.condition.endDate = val[1].format('yyyy-MM-dd');
                this.getData();
            },
        },
        chartDaterange: {
            deep: true,
            immediate: true,
            handler: function(val, oldVal) {
                this.getEchartsData();
            },
        },
        adaptation:{
            deep: true,//对象深度监测
            handler: function (){
                this.echarts.resize();
            }
        }
    },
    created() {

    },
    mounted() {
        this.echarts = echarts.init(this.$refs.echarts);
    },
    methods: {
        setOption(data){
            var xAxisData = [];
            var seriesData = [];
            var maxYAxis = 1;

            data.some(r=>{

                for(let k in r){
                    xAxisData.push(k)
                    seriesData.push(r[k])
                    if(r[k]>maxYAxis){
                        maxYAxis = r[k];
                    }
                }

            })
            var option = {
                grid: {
                    top:'20px',
                    left: '25px',
                    right: '0',
                    bottom: '0',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: true,
                    splitNumber:1,
                    axisLine:{
                        show:false,
                    },
                    axisTick:{
                        show:false,
                    },
                    axisLabel:{
                        margin:15,
                    },
                    data:xAxisData,
                },
                yAxis: {
                    type: 'value',
                    axisLabel:{
                        // align:"left",
                        verticalAlign:'bottom',
                        lineHeight:20,
                        inside:true,
                        margin:-25,
                        formatter: function (value, index) {
                            return value==maxYAxis?`${value} 单位：点击(次)`:value;
                        }
                    },
                    axisTick: {
                        show: true,
                        length: 150,
                        lineStyle: {
                            color: "#ccc"
                        },
                    },
                    minInterval:1,
                },
                series: [{
                    type: 'line',
                    smooth: true,
                    lineStyle: {
                        width: 2,
                        color:'#00C2CE',
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {offset: 0,color: 'rgba(0, 194, 206,1)'},
                            {offset: 1,color: 'rgba(0, 194, 206,0)'},
                        ])
                    },
                    data: seriesData
                }]
            };
            this.echarts.setOption(option);
            this.echarts.resize();

            this.echartsLoading = false;

        },
        async getEchartsData(){

            this.echartsLoading = true;
            const { data:townData } = await this.$api['admin/dict'].findAllDictByCode('town', true);

            var townIds = [];
            townData.some(r=>{
                townIds.push(r.name);
            });

            this.$api['admin/statistics'].statisticsByTown({
                townIds,
                startDate:this.chartDaterange[0].format('yyyy-MM-dd'),
                endDate:this.chartDaterange[1].format('yyyy-MM-dd'),
            }).then( res => {

                this.setOption(res.data);

            }).catch(err=>{
                console.log(err);
                this.echartsLoading = false;
            })

        }
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
    .userOnline {
        padding:20px;
        .userOnlineSub {
            height:calc(50% - 10px);
            background:#fff;
            padding:20px;
            border-radius:4px;
            +.userOnlineSub {
                margin-top:20px;
            }
        }
        .userOnlineTitle {
            padding:0 0 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
                position:relative;
                font-size:18px;
                line-height:18px;
                padding:0 0 0 13px;
                text-shadow: 0px 0px 1px #262626;
                &:before,
                &:after, {
                    content:"";
                    position:absolute;
                    left:0;
                    width:6px;
                    height:6px;
                }
                &:before {top:1px;background:#f86220;}
                &:after {bottom:1px;background:#00b8ff;}
            }
            .activeUser {
                font-size:14px;
                text-shadow: 0px 0px 1px #262626;
                margin-right:30px;
            }
        }
        .userOnlineCon {
            height:calc(100% - 43px);
            .el-table {
                height: calc(100% - 53px);
            }
            /deep/.el-pagination {
                padding: 20px 0 5px 0;
                text-align: right;
                .el-pagination__sizes {
                    margin:0;
                    .el-select {
                        .el-input {
                            margin-right:0;
                        }
                    }
                }
            }
        }
    }
</style>
